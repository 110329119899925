<template>
  <b-row>
    <b-col class="md-12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header class="justify-content-start p-0 pb-1 mb-1 border-bottom">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              Rounds of {{ programTitle }}
            </h3>
          </b-card-header>

          <b-card
            v-if="rounds.length === 0"
            class="ecommerce-card"
          >
            <b-card-text class="text-center">
              <p>No rounds found for this program.</p>
            </b-card-text>
          </b-card>

          <section
            v-else
            class="grid-view"
          >
            <b-card
              v-for="(program, i) in rounds"
              :key="i"
              :title="program.title"
              class="ecommerce-card border border-secondary"
              img-alt="card img"
              img-top
              no-body
            >
              <b-card-body>
                <b-card-text>
                  <b-card-title class="mb-1">
                    {{ program.title }}
                    <b-badge
                      class="mr-25"
                      size="lg"
                      variant="primary"
                    >
                      Round {{ program.round_number }}
                    </b-badge>
                    <hr>
                  </b-card-title>
                  <span class="h5 d-block">Application Status: <b-badge
                    :variant="program.status === 'enabled' ? 'success' : ''"
                    class="text-capitalize"
                    pill
                    size="lg"
                    v-text="program.status"
                  /></span>
                  <span class="h5 d-block">
                    Review Status: <b-badge
                      :variant="program.review_status === 'enabled' ? 'success' : ''"
                      class="text-capitalize"
                      pill
                      size="lg"
                      v-text="program.review_status"
                    /></span>
                </b-card-text>
              </b-card-body>
              <!-- Product Actions -->
              <div class="item-options text-center">
                <b-link
                  :to="{ name: 'mentor-program-rounds-details', params: { id: $route.params.id, pid: $route.params.pid, sid: program.id } }"
                  class="btn btn-primary btn-cart"
                >
                  <span>Open</span>
                </b-link>
              </div>
            </b-card>
          </section>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BLink,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/utils/auth'

export default {
  components: {
    BButton,
    BCardHeader,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BBadge,
    BOverlay,
    BLink,
    BRow,
    BCol,
  },
  data() {
    return {
      rounds: [], // Array to store program data
      userOrgs: getUserData().id, // User organization ID
    }
  },
  computed: {
    /**
     * Computes the title of the program.
     * @returns {string} The title of the program.
     */
    programTitle() {
      return this.rounds.length > 0 ? this.rounds[0].programs_basicinfo.title : '_'
    },
  },
  apollo: {
    rounds: {
      /**
       * GraphQL query to fetch programs data.
       * @returns {Object} GraphQL query object.
       */
      query() {
        return gql`
          {
            programs_applicationformstable(where: {program_id: {_eq: ${this.$route.params.id}}, review_status: {_neq: "draft"}}, order_by: {round_number: desc}) {
              title
              round_number
              review_status
              status
              id
              programs_basicinfo {
                  title
                }
              }
           }
             `
      },
      /**
       * Updates the programs data after fetching from the server.
       * @param {Object} data - The fetched data.
       * @returns {Array} Sorted programs data.
       */
      update: data => data.programs_applicationformstable,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
